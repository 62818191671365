export const ENV = (() => {
  switch (process.env.REACT_APP_ENV) {
    case 'production':
      return 'prod';
    case 'staging':
      return 'stg';
    default:
      return 'dev';
  }
})();

//eslint-disable-next-line
export const API_DOMAIN =
  process.env.REACT_APP_LOCALHOST !== '1'
    ? `https://api.${ENV}.bapi.belmond.com`
    : 'http://localhost:4000';

global.UTCDate = function UTCDate(...args) {
  const date = new Date(...args);
  return new Date(date.getTime() - date.getTimezoneOffset() * -60000);
};

const baseAuthConfig = {
  issuer: 'https://belmond.okta.com',
  redirectUri: `${window.location.origin}/login/callback`,
  scopes: ['openid', 'profile', 'email'],
  pkce: true,
  disableHttpsCheck: true,
};

export const authConfig = (() => {
  switch (process.env.REACT_APP_ENV) {
    case 'production':
      return {
        ...baseAuthConfig,
        clientId: '0oa54xvhmrbWZMjSh417',
      };
    case 'staging':
      return {
        ...baseAuthConfig,
        clientId: '0oa54xw5e6iuhXdqW417',
      };
    default:
      return {
        ...baseAuthConfig,
        clientId: '0oa54otop4ZgE6gOQ417',
      };
  }
})();
